import { createMemo, createSignal, createUniqueId, For, Show, Suspense } from 'solid-js';
import { createAsync, useParams } from '@solidjs/router';
import {
	Avatar,
	Button,
	Dialog,
	DialogContent,
	DialogTrigger,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	HorizontalRule,
	Link,
	LinkButton,
	Section,
} from '@troon/ui';
import { Popover as KPopover } from '@kobalte/core/popover';
import { Trigger as DropdownTrigger } from '@kobalte/core/dropdown-menu';
import { IconHamburgerMd } from '@troon/icons/hamburger-md';
import { IconCloseMd } from '@troon/icons/close-md';
import { IconLogo } from '@troon/icons/logo';
import { IconHouse01 } from '@troon/icons/house-01';
import { IconUsers } from '@troon/icons/users';
import { IconFileDocument } from '@troon/icons/file-document';
import { IconGolfCart } from '@troon/icons/golf-cart';
import { Dynamic } from 'solid-js/web';
import { IconChevronDown } from '@troon/icons/chevron-down';
import { IconUser02 } from '@troon/icons/user-02';
import { IconLogOut } from '@troon/icons/log-out';
import { IconChartBarVertical01 } from '@troon/icons/chart-bar-vertical-01';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconCheck } from '@troon/icons/check';
import { IconCircleWarning } from '@troon/icons/circle-warning';
import { requireLoggedIn, useCurrentFacility, useUser } from '../providers/root';
import { FacilitySelector } from '../components/facility-selector';
import type { IconComponent } from '@troon/icons';
import type { RouteSectionProps } from '@solidjs/router';
import type { ApiResponse } from '@troon/api-client';

export default function AuthGuard(props: RouteSectionProps) {
	const userData = createAsync(() => requireLoggedIn());

	return (
		<Suspense>
			<Show when={userData()}>
				<div class="flex w-dvw flex-col justify-stretch overflow-hidden xl:flex-row">
					<SiteHeader />
					<main class="flex w-full flex-col gap-8 overflow-hidden xl:ms-96">{props.children}</main>
				</div>
			</Show>
		</Suspense>
	);
}

function SiteHeader() {
	const [menuOpen, setMenuOpen] = createSignal(false);
	const menuId = createUniqueId();

	return (
		<header class="flex w-full shrink-0 items-center justify-start gap-x-4 border-b border-neutral bg-white py-2 xl:fixed xl:inset-y-0 xl:h-screen xl:max-w-96 xl:flex-col xl:items-stretch xl:overflow-y-auto xl:border-b-0 xl:border-e">
			<div class="xl:hidden">
				<KPopover
					fitViewport
					modal
					preventScroll
					sameWidth={false}
					placement="bottom"
					gutter={15}
					open={menuOpen()}
					onOpenChange={(open) => {
						setMenuOpen(open);
					}}
				>
					<KPopover.Trigger
						as={Button}
						aria-haspopup="dialog"
						appearance="transparent-current"
						size="sm"
						class="shrink grow-0 rounded-md px-2 py-1 text-xl xl:hidden"
						aria-expanded={menuOpen()}
						aria-controls={menuOpen() ? menuId : undefined}
					>
						<Show
							when={menuOpen()}
							fallback={
								<>
									<IconHamburgerMd class="shrink-0" />
									<span class="sr-only">Show Menu</span>
								</>
							}
						>
							<IconCloseMd class="shrink-0" />
							<span class="sr-only">Hide Menu</span>
						</Show>
					</KPopover.Trigger>
					<KPopover.Portal>
						<KPopover.Content
							id={menuId} // eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="fixed -start-2 z-40 flex h-[calc(var(--kb-popper-content-available-height)+0.55rem)] w-screen flex-col justify-between overflow-hidden bg-white p-2 animate-out fade-out slide-out-to-bottom anim-duration-300 ui-expanded:duration-200 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:slide-in-from-bottom ui-expanded:anim-duration-200"
						>
							<Navigation onNavigate={() => setMenuOpen(false)} />
						</KPopover.Content>
					</KPopover.Portal>
				</KPopover>
			</div>
			<div class="px-4">
				<LinkButton appearance="current" href="/" class="w-fit">
					<IconLogo class="w-32" />
				</LinkButton>
			</div>
			<div class="hidden h-full flex-col justify-stretch xl:flex">
				<Navigation onNavigate={() => {}} />
			</div>
		</header>
	);
}

function Navigation(props: { onNavigate: () => void }) {
	const user = useUser();
	const params = useParams<{ facility?: string }>();
	const facility = useCurrentFacility();
	return (
		<nav class="flex h-full grow flex-col justify-between gap-4">
			<div class="flex h-full grow flex-col gap-2 p-4">
				<FacilitySelector onSelect={props.onNavigate} />
				<Suspense>
					<Show when={facility() && params.facility}>
						<ul class="flex flex-col gap-1">
							<For each={navItems.filter((item) => !item.filter || item.filter(facility()!))}>
								{(item) => (
									<li>
										<Link
											href={`/facility/${params.facility}${item.pathname}`}
											onClick={props.onNavigate}
											class="group relative flex flex-row items-center gap-2 overflow-hidden rounded bg-white p-4 text-neutral-950 transition-colors duration-200 hover:bg-brand-100 focus-visible:ring-0 focus-visible:ring-offset-0 aria-current-page:bg-brand-100"
										>
											<span class="absolute inset-y-0 left-0 w-4 rounded border-s-0 border-s-white transition-all duration-200 group-hover:border-s-brand-100 group-hover:bg-brand-100 group-data-[expanded]/viewport:hidden group-aria-current-page:block group-aria-current-page:border-s-4 group-aria-current-page:border-s-brand group-aria-current-page:bg-brand-100 group-data-[expanded]/viewport:group-aria-current-page:hidden" />
											<Dynamic component={item.icon} class="shrink-0 grow-0 text-lg text-brand" />
											{item.name}
										</Link>
									</li>
								)}
							</For>
						</ul>
					</Show>

					<Show when={facility()?.facility.supportsTroonAccessAssociateCommisions && user()}>
						<Section appearance="contained" class="mt-4 gap-4 bg-brand text-base text-white">
							<p class="text-xl font-medium">Earn Commission!</p>
							<p class="text-base">Earn $25 for every Troon Access membership you sell in the shop.</p>
							<HorizontalRule />
							<div class="flex flex-col gap-2">
								<div class="flex justify-between">
									<p class="text-sm font-medium">Troon Access Sales</p>
									<p class="text-base font-medium">{user()!.troonAccessUpsellInfo.subscriptionsSold}</p>
								</div>
								<div class="flex justify-between">
									<p class="text-sm font-medium">Total Commissions</p>
									<p class="text-base font-medium">{user()!.troonAccessUpsellInfo.commission.displayValue}</p>
								</div>
							</div>
							<TroonAccessSalesInfo troonAccessUpsellInfo={user()!.troonAccessUpsellInfo} />
						</Section>
					</Show>
				</Suspense>
			</div>
			<div class="flex justify-stretch border-t border-neutral p-2">
				<DropdownMenu gutter={10}>
					<DropdownTrigger class="w-full rounded p-2 hover:bg-brand-100">
						<div class="flex items-center justify-stretch gap-4">
							<Avatar
								class="size-12 shrink-0 grow-0 rounded-full bg-brand text-brand-100"
								firstName={user()?.firstName}
								lastName={user()?.lastName}
							/>
							<div class="flex grow flex-col truncate text-start">
								<div class="truncate text-lg font-semibold">
									{user()?.firstName} {user()?.lastName}
								</div>
								<div class="truncate text-sm">{user()?.email}</div>
							</div>
							<IconChevronDown class="size-4 shrink-0 rotate-180 text-brand" />
						</div>
					</DropdownTrigger>
					<DropdownMenuContent>
						<DropdownMenuItem as={Link} href="/auth" class="p-3 ps-3" onClick={props.onNavigate}>
							<IconUser02 class="text-brand" />
							My account
						</DropdownMenuItem>
						<HorizontalRule />
						<DropdownMenuItem as={Link} href="/auth/logout" class="justify-center" onClick={props.onNavigate}>
							<IconLogOut /> Log out
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</nav>
	);
}

type Facility = Exclude<ApiResponse<'get', '/v0/operator/facilities'>['facilities'], undefined>[number];

const navItems: Array<{
	icon: IconComponent;
	name: string;
	pathname: string;
	filter?: (facility: Facility) => boolean;
}> = [
	{ icon: IconHouse01, name: 'Home', pathname: '/' },
	{ icon: IconUsers, name: 'Customers', pathname: '/customer' },
	{ icon: IconFileDocument, name: 'Resources', pathname: '/resources' },
	{ icon: IconChartBarVertical01, name: 'Analytics', pathname: '/analytics' },
	{
		icon: IconGolfCart,
		name: 'Associates',
		pathname: '/settings/members',
		filter: (facility: Facility) => facility.role === 'ADMIN',
	},
];

function TroonAccessSalesInfo(props: {
	troonAccessUpsellInfo: ApiResponse<'get', '/v0/operator/auth/me'>['troonAccessUpsellInfo'];
}) {
	const bonusInfo = createMemo(() => {
		if (!props.troonAccessUpsellInfo) {
			return { bonusSold: 0, totalForBonus: 10 };
		}
		const subscriptionsForBonus = props.troonAccessUpsellInfo.subscriptionsForBonus;
		const subscriptionsSoldForBonus =
			props.troonAccessUpsellInfo.subscriptionsForBonus - props.troonAccessUpsellInfo.subscriptionsToNextBonus;
		const percentToBonus =
			props.troonAccessUpsellInfo.subscriptionsSold > 0 ? (subscriptionsSoldForBonus / subscriptionsForBonus) * 100 : 0;
		return { subscriptionsForBonus, subscriptionsSoldForBonus, percentToBonus };
	});
	return (
		<Dialog key="troon-access-upsell-info">
			<DialogTrigger class="text-sm font-medium text-white" as="p">
				<div class="flex items-center gap-2">
					<IconCircleWarning class="size-6 text-white" />
					Learn More
				</div>
			</DialogTrigger>
			<DialogContent height="fit" header="Troon Access Commission" headerLevel="h2">
				<div class="flex flex-col gap-6">
					<p>Earn $25 for every Troon Access membership you sell in the shop.</p>

					<div class="flex gap-4 rounded-lg border border-neutral bg-white p-4">
						<div
							class={twJoin(
								'flex size-10 items-center justify-center rounded-full',
								props.troonAccessUpsellInfo?.subscriptionsSold > 0 ? 'bg-brand-400' : 'bg-neutral-400',
							)}
						>
							<IconCheck
								class={twJoin(props.troonAccessUpsellInfo?.subscriptionsSold > 0 ? 'text-white' : 'text-neutral-700')}
							/>
						</div>
						<div class="flex flex-col">
							<p class="font-medium">Earn $100 on first sale!</p>
							<p class="text-neutral-700">Sell your first Troon Access membership earn $100.</p>
						</div>
					</div>

					<div class="flex gap-4 rounded-lg border border-neutral bg-white p-4">
						<div class="flex gap-4">
							<div
								class={twJoin(
									'flex size-10 items-center justify-center rounded-full',
									props.troonAccessUpsellInfo?.subscriptionsToNextBonus === 0 ? 'bg-brand-400' : 'bg-neutral-400',
								)}
							>
								<IconCheck
									class={twJoin(
										props.troonAccessUpsellInfo?.subscriptionsToNextBonus === 0 ? 'text-white' : 'text-neutral-700',
									)}
								/>
							</div>
							<div class="flex w-full flex-col items-start gap-4">
								<div class="flex flex-col">
									<p class="font-medium">Sell 10 memberships - $100 Bonus</p>
									<p class="text-neutral-700">When you sell 10 memberships, earn a $100 bonus.</p>
								</div>
								<div class="flex w-full flex-col gap-2">
									<div class="flex items-start justify-between">
										<p class="text-base font-medium">Progress</p>
										<p class="text-right font-medium">
											{bonusInfo().subscriptionsSoldForBonus}/{bonusInfo().subscriptionsForBonus}
										</p>
									</div>
									<div class="relative h-3 w-full rounded-full bg-neutral-200">
										<div
											class="h-full rounded-full bg-brand-400"
											style={{
												width: `${bonusInfo().percentToBonus}%`,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<HorizontalRule />

					<div class="flex flex-col gap-2 text-sm">
						<p class="font-medium">Numbers Don't Look Right?</p>
						<p class="text-neutral-700">
							Make sure you are logged into your own Troon Operator account when you make a sale. If you do not have
							your own account, reach out to your manager to get issued one.
						</p>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
